import React from "react";

import Layout from "../other/layout";
import SEO from "../other/seo";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import FadeIn from 'react-fade-in';
import { graphql, useStaticQuery } from "gatsby";
import openChatBot from "../functions/openChatBot";
const DigitalTransformation = () => {
  const intl = useIntl();
  // const data = useStaticQuery(graphql`
  //   query {
  //     digitalTransformSlide: file(relativePath: { eq: "bodybg.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1500) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //     Ceo: file(relativePath: { eq: "CEO.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 750) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //   }

  // `);


  const data = useStaticQuery(graphql`
    query {
      digitalTransformSlide: file(relativePath: { eq: "bodybg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Ceo: file(relativePath: { eq: "CEO.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 245) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
    }
  `);
  return (

    <Layout>
      <FadeIn>
        <SEO title={intl.formatMessage({ id: "innerpage-58" })} />

        <div>
          <FadeIn delay={200} transitionDuration={700}>
            <div className="mainlyt">
              <Img fluid={data.digitalTransformSlide.childImageSharp.fluid}
                className="img-responsive"
              />
              <div className="mainlyh">
                <div className="mainlyl">{intl.formatMessage({ id: "innerpage-57" })}</div>
                
              </div>
            </div>
          </FadeIn>
          <div>


            <div className="bodyTextCss">
              <FadeIn className="BodyContainer bodytext" delay={210} transitionDuration={500}>

                <h5>{intl.formatMessage({ id: "innerpage-58" })}</h5>
                <h6> {intl.formatMessage({ id: "innerpage-59" })}<br/>{intl.formatMessage({ id: "innerpage-221" })}<br/>{intl.formatMessage({ id: "innerpage-222" })}</h6>
              <p>{intl.formatMessage({ id: "innerpage-60" })}</p>
              <p>{intl.formatMessage({ id: "innerpage-61" })}</p>
              <h6>{intl.formatMessage({ id: "innerpage-62" })}</h6>
              <p>{intl.formatMessage({ id: "innerpage-63" })}</p>
              <p>{intl.formatMessage({ id: "innerpage-64" })}</p>
              </FadeIn>
            </div>

          </div>
        </div>
      </FadeIn>
    </Layout>

  );
};

export default DigitalTransformation;
